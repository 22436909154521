<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2019-11-19 10:16:22
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:31:36
 -->
<template>
  <div class="add-course-wrap">
    <h2 class="tishi">
      <i></i>
      请您遵守国家相关规定，切勿上传低俗色情、暴力恐怖、谣言诈骗、侵权盗版等相关内容，叮叮开课保有依据国家规定及平台规则进行处理的权利。
    </h2>
    <el-button size="medium" @click="toggleShowCopy">一键复制</el-button>
    <!-- <el-collapse v-model="activeNames"> -->
    <!--基本信息-->
    <!-- <el-collapse-item :title="`基本信息（${title1}/6）`" name="1" id="bInfo"> -->
    <div class="inner-content">
      <BasicInfo
        ref="basicInfo"
        @titleChange="titleChange"
        :courseType="courseType"
        :alreadyCopyTime="alreadyCopyTime"
      ></BasicInfo>
    </div>
    <!-- </el-collapse-item> -->
    <!-- </el-collapse> -->
    <el-button type="primary" @click.stop="saveAll(1)" style="width: 124px">
      保存
    </el-button>
    <!-- <fixedMenu
      :rightMenu="rightMenu"
      :activeId="activeId"
      @toTargetPos="toTargetPos"
    ></fixedMenu> -->
    <CopyPop
      v-if="isShowCopy"
      @closeFn="toggleShowCopy"
      :course_type="courseType"
      @copySuccess="copySuccess"
    ></CopyPop>
  </div>
</template>
<script>
import BasicInfo from '@/components/course/BasicInfo'
import CopyPop from '@/components/coursePop/copyPop'
import API from '@/request/api'
// import fixedMenu from '@/components/course/FlxedMenu'
import beforeLeave from '@/mixin/LeavePagePrompt'
import { mapState } from 'vuex'

export default {
  mixins: [beforeLeave],

  data() {
    return {
      courseType: '',
      isShowCopy: false,
      activeId: 1,
      activeNames: ['1', '2', '3', '4'],
      rightMenu: [
        { name: '基本信息', ref: 'bInfo' },
        // { name: '添加章节', ref: 'aSection' },
        // { name: '课程设置', ref: 'cSetting' },
        // { name: '关联售卖', ref: 'aSelling' },
      ],
      title1: 2,
      title2: 0,
      courseList: [],
      // 一键复制成功
      alreadyCopyTime: 0,
    }
  },
  components: {
    BasicInfo,
    // fixedMenu,
    CopyPop,
  },

  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 显示一键复制弹窗
    toggleShowCopy() {
      this.isShowCopy = !this.isShowCopy
    },
    // 复制成功触发
    copySuccess() {
      this.alreadyCopyTime = new Date().getTime()
    },
    toTargetPos(index) {
      if (!this.activeNames.includes(`${index}`)) {
        this.activeNames.push(`${index}`)
      }
    },
    // 数量改变
    titleChange(type, added) {
      switch (Number(type)) {
        case 1:
          this.title1 = added
          break
        case 2:
          this.title2 = added
          break
      }
    },
    // 保存
    saveAll(val) {
      // 基础信息
      this.basicInfoRuleForm = this.$refs.basicInfo.ruleForm
      // 添加章节的列表信息
      if (!this.basicInfoRuleForm.name.trim()) {
        this.$root.prompt('请输入课程名称')
        return
      }
      // if (!this.basicInfoRuleForm.new_cabout) {
      //   this.$root.prompt('请输入课程简介')
      //   return
      // }
      if (
        this.basicInfoRuleForm.price_type == 3 &&
        !this.basicInfoRuleForm.fee_pwd
      ) {
        this.$root.prompt('请输入观看密码')
        return
      } else if (
        this.basicInfoRuleForm.price_type == 1 &&
        !this.basicInfoRuleForm.price
      ) {
        this.$root.prompt('请输入价格')
        return
      }
      if (
        this.basicInfoRuleForm.price_type == 3 &&
        this.basicInfoRuleForm.fee_pwd &&
        !/^[a-zA-Z0-9]{1,8}$/.test(this.basicInfoRuleForm.fee_pwd)
      ) {
        this.$root.prompt('请输入有效密码')
        return
      }
      const reg =
        /(^[1-9](\d+)?(\.\d{1,2})?$)|(^[1-9]$)|(^\d\.[1-9]{1,2}$)|(^\d\.[0]{1}[1-9]{1}$|(^\d\.[1-9]{1}[0]{1}$)$)/
      if (
        (this.basicInfoRuleForm.price_type == 1 &&
          !reg.exec(this.basicInfoRuleForm.price)) ||
        (this.basicInfoRuleForm.price_type == 1 &&
          this.basicInfoRuleForm.costprice &&
          !reg.exec(this.basicInfoRuleForm.costprice))
      ) {
        this.$root.prompt('请输入有效金额')
        return
      }
      // if (this.courseList.length == 0) {
      //   this.$root.prompt('请添加章节信息')
      //   return
      // }
      if (!this.verifyScheduleList(this.courseList)) {
        // 清空操作
        if (this.basicInfoRuleForm.price_type != 3) {
          this.basicInfoRuleForm.fee_pwd = ''
        }
        if (this.basicInfoRuleForm.price_type != 1) {
          this.basicInfoRuleForm.price = ''
          // this.basicInfoRuleForm.costprice = ''
        }
        if (
          this.basicInfoRuleForm.price_type != 1 &&
          this.basicInfoRuleForm.price_type != 2
        ) {
          this.basicInfoRuleForm.costprice = ''
        }
        if (val && val == 1) {
          this.createCourse(val)
        } else {
          this.createCourse()
        }
      }
    },

    // 验证章节列表
    verifyScheduleList(value) {
      let flag
      value.forEach((item) => {
        if (!item.name) {
          flag = true
          this.$root.prompt('章节内容不能为空')
          return
        }
        if (item.is_directory == 2 && !item.teacher_id) {
          flag = true
          this.$root.prompt('章节上课老师不能为空')
          return
        }
        if (!flag && Array.isArray(item.children)) {
          flag = this.verifyScheduleList(item.children)
        }
      })
      return flag
    },

    // 创建课程
    createCourse(val) {
      const self = this
      this.postData = Object.assign({}, self.basicInfoRuleForm)

      // 处理课程分类数组
      let resArr = []
      if (this.basicInfoRuleForm.course_set_id.length > 0) {
        this.basicInfoRuleForm.course_set_id.forEach((item) => {
          if (item.length > 1) {
            resArr.push(item[1])
          } else {
            resArr.push(item[0])
          }
        })
      }

      self.postData['course_set_id'] = resArr
      this.postData['courseList'] = self.courseList
      this.postData['course_content_id'] = self.course_content_id

      self.$http({
        name: API.createCourse_Api.name,
        url: API.createCourse_Api.url,
        data: this.postData,
        callback(res) {
          if (res.code == 200) {
            localStorage.removeItem('copyCourseInfo')
            self.$root.prompt({
              msg: '创建课程成功',
              type: 'success',
            })
            self.leaveConfirm = 1
            if (val && val == 1) {
              self.$router.replace({
                path: '/courseLive/EditCourse',
                query: {
                  course_id: res.data,
                  course_type: 1,
                  index: 2,
                },
              })
            } else {
              self.$router.replace({
                path: '/courseLive',
              })
            }
          }
        },
      })
    },
    // 预创建课程
    precreateCourse() {
      const self = this
      self.$http({
        name: API.createCourse_Api.name,
        url: API.createCourse_Api.url,
        callback(res) {
          if (res.code == 200) {
            console.log(res, 'pre')
          }
        },
        error(error) {
          if (error.code == 204) {
            console.log(error, 'preerror')
          }
        },
      })
    },
    scrollFn() {
      window.onscroll = () => {
        let top = document.documentElement
          ? document.documentElement.scrollTop
          : document.body.scrollTop
        const rightMenu = this.rightMenu
        for (let i = 0; i < rightMenu.length; i += 1) {
          if (!document.getElementById(rightMenu[i]['ref'])) {
            break
          }
          let topDis = document.getElementById(rightMenu[i]['ref']).offsetTop
          if (rightMenu[i]['ref'] != 'aSelling') {
            if (top > topDis - 200) {
              this.activeId = ''
              this.activeId = i + 1
            } else {
              break
            }
          } else {
            if (top > topDis - 600) {
              this.activeId = ''
              this.activeId = i + 1
            } else {
              break
            }
          }
        }
      }
    },
  },
  created() {
    this.courseType = this.$route.query.courseType
  },
  mounted() {
    this.precreateCourse()
    this.scrollFn()
  },
}
</script>
<style lang="scss" scoped>
.add-course-wrap {
  padding: 20px;
  background: #fff;
  .tishi {
    display: flex;
    align-items: center;
    padding: 12px 0;
    font-size: 12px;
    color: rgba(129, 100, 64, 1);
    line-height: 16px;
    background: #fff1de;
    box-sizing: border-box;
    i {
      display: inline-block;
      width: 13px;
      height: 14px;
      margin: 0 10px;
      background: url(../../assets/img/1.1.2/ico_jcbs.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .tishi + ::v-deep .el-button {
    margin: 30px 0 28px;
    border: 1px solid #0aa29b;
    color: #0aa29b;
  }
  ::v-deep .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  ::v-deep .el-collapse-item__header.is-active {
    border-bottom: 1px solid #e2e2e2;
  }
  .inner-content {
    padding-top: 40px;
  }
  ::v-deep .el-collapse-item__arrow.is-active {
    color: #0aa29b;
  }
  #aSelling ::v-deep .el-collapse-item__header {
    position: relative;
    &:after {
      position: absolute;
      left: 106px;
      height: 48px;
      content: '可关联课程在课程详情推荐模块展示';
      font-size: 12px;
      line-height: 53px;
      color: #666;
    }
  }
  #aSection ::v-deep .el-collapse-item__header {
    position: relative;
    &:after {
      position: absolute;
      left: 106px;
      height: 48px;
      content: '*';
      font-size: 12px;
      line-height: 53px;
      color: #ff3535;
    }
  }
  ::v-deep .el-collapse-item:last-child {
    &.is-active .el-collapse-item__wrap {
      border-bottom: 1px solid #fff;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
  }
  .saveButton {
    width: 126px;
    height: 42px;
    margin-top: 10px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    background: rgba(10, 162, 155, 1);
    border: rgba(10, 162, 155, 1);
  }
}
</style>
